import React from "react";

export default function Category() {
  return (
    <div>
      <section className="oc-category-area pt-110 pb-70">
        <div className="container">
          <div className="row wow fadeInUp">
            <div className="col-lg-12">
              <div className="section-title1 pos-rel text-center mb-40">
                <h2 className="section-main-title1">How it works</h2>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp">
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="oc-category mb-55">
                <div className="oc-category-img">
                  <a>
                  <img src="assets/img/globl/connect_wallet.jpg" alt="Connect Wallet" />
                  </a>
                </div>
                <h5 className="oc-category-title">
                  <a>Connect Wallet
                  </a>
                </h5>
                <p>
                Get started by connecting your wallet to the Global Grow website. 
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="oc-category mb-55">
                <div className="oc-category-img">
                  <a>
                  <img src="assets/img/globl/choose_package.jpg" alt="Choose Package" />
                  </a>
                </div>
                <h5 className="oc-category-title">
                  <a>Choose Package</a>
                  <p>
                  Select your investment amount, starting from $50 to however higher you want to go. 

                  </p>
                </h5>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="oc-category mb-55">
                <div className="oc-category-img">
                  <a>
                    <img src="assets/img/globl/deploy_trading_bot.jpg" alt="Deploy Trading Bot" />
                  </a>
                </div>
                <h5 className="oc-category-title">
                  <a>Deploy Trading Bot</a>
                </h5>
                <p>
                You would get a bot to automate your trades in crypto & forex markets. 

                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-6">
              <div className="oc-category mb-55">
                <div className="oc-category-img">
                  <a>
                  <img src="assets/img/globl/continue_earning.jpg" alt="Continue Earning" />
                  </a>
                </div>
                <h5 className="oc-category-title">
                  <a>Continue Earning
                  </a>
                </h5>
                <p>
                Keep your bot active, refer more people & continue earning incentives. 	

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
