import React from 'react'

export default function Main() {
  return (
    <div>
        <section className="oc-wallet-area ">
  <div className="container">
    <div className="row wow fadeInUp">
      <div className="col-12">
        <div className="section-title1 pos-rel text-center mb-40">
          <h2 className="section-main-title1">Our Partners
          </h2>
          <p>Global Grow has a variety of partners 
          </p>
        </div>
      </div>
    </div>
    <div className="row wow fadeInUp justify-content-center">
      <div className="col-xl-10">
        <div className="row">
          <div className="col-xl-2 wallet-col-width-20">
            <a href="#">
              <div className="oc-wallet text-center mb-30">
                <div className="oc-wallet-icon text-center">
                  <img src="assets/img/globl/bscscan.svg" alt="BSCScan" height={80} />
                </div>
                <h5 className="oc-wallet-title">BSCScan</h5>
              </div>
            </a>
          </div>
          <div className="col-xl-2 wallet-col-width-20">
            <a href="#">
              <div className="oc-wallet text-center mb-30">
                <div className="oc-wallet-icon text-center">
                <img src="assets/img/globl/brightbit.png" alt="BrightBit" height={80} />
                </div>
                <h5 className="oc-wallet-title">BrightBit</h5>
              </div>
            </a>
          </div>
          <div className="col-xl-2 wallet-col-width-20">
            <a href="#">
              <div className="oc-wallet text-center mb-30">
                <div className="oc-wallet-icon text-center">
                <img src="assets/img/globl/trust_wallet.svg" alt="Trust Wallet" height={80} />
                </div>
                <h5 className="oc-wallet-title">Trust Wallet</h5>
              </div>
            </a>
          </div>
          <div className="col-xl-2 wallet-col-width-20">
            <a href="#">
              <div className="oc-wallet text-center mb-30">
                <div className="oc-wallet-icon text-center">
                <img src="assets/img/globl/crypto_potato.png" alt="CryptoPotato" height={80} />
                </div>
                <h5 className="oc-wallet-title">CryptoPotato</h5>
              </div>
            </a>
          </div>
          <div className="col-xl-2 wallet-col-width-20">
            <a href="#">
              <div className="oc-wallet text-center mb-30">
                <div className="oc-wallet-icon text-center">
                <img src="assets/img/globl/metamask.svg" alt="Metamask" height={80} />
                </div>
                <h5 className="oc-wallet-title">Metamask</h5>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
