import React from "react";
import Header2 from "../Coman/Header2";
import DashboardMain from "./DashboardMain";
import Sidebar from "../Coman/Sidebar";

export default function Dashboard() {
  return (
    <div>
      <Header2 />
      <Sidebar />

      <DashboardMain />
    </div>
  );
}
