import React from "react";
import Header2 from "../Coman/Header2";
import Sidebar from "../Coman/Sidebar";

export default function Staking() {
  return (
    <div cl>
      <Header2 />
      <Sidebar />
      <div style={{ backgroundColor: "#eff1f5" }}>
        <div className="banner-area banner-area3 pos-rel pt-130 vh-100">
          <div className="container c-container-1">
<h2>Staking</h2>
          <div className="rank-list-container wow fadeInUp mt-4" style={{visibility: 'visible', animationName: 'fadeInUp'}}>
  <div className="rank-list-wrapper mb-30">
    <div className="rank-list-row-heading">
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl">Sr</div>
        <div className="rank-list-cell rank-list-cell-market">Reward Eligibility	</div>
        <div className="rank-list-cell rank-list-cell-volume">Stake($)	</div>
        <div className="rank-list-cell rank-list-cell-hours">Rate</div>
        <div className="rank-list-cell rank-list-cell-days">Datetime</div>
        <div className="rank-list-cell rank-list-cell-bids me-4">Status</div>
    
      </div>
    </div>
    <div className="rank-list-items">
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
     
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>

      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>

        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
  
      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
  
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
      
      </div>
  
    </div>
  </div>
</div>

          </div>
        </div>
      </div>
    </div>
  );
}
