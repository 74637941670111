import React from "react";

export default function Create() {
  return (
    <div>
      <section className="work-process-area pt-110 ">
        <div className="container">
          <div className="row wow fadeInUp">
            <div className="col-lg-12">
              <div className="section-title1 pos-rel text-center mb-40">
                <h2 className="section-main-title1">Why Choose Global Grow</h2>
                {/* <p>Here are a few reasons why you should choose Oction for sell your NFT</p> */}
              </div>
            </div>
          </div>
          <div className="work-processes">
            <div className="row wow fadeInUp">
              <div className="col-lg-4 col-md-6">
                <div className="work-process-single mb-30 pos-rel">
                  <div className="step"></div>
                  <div className="work-process-content">
                    <div className="process-icon mb-40">
                      <img
                        src="assets/img/globl/automated_trading_bots.png"
                        alt="Automated Trading Bots"
                      />
                    </div>
                    <h4 className="process-title">
                      <a href="">Automated Trading Bots</a>
                    </h4>
                    <p className>
                      Our advanced trading bots operate 24/7, analyzing the
                      market and executing trades for you, so you can maximize
                      your profits without constant monitoring. They are
                      designed to make trading easier and more efficient,
                      regardless of your experience level.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="work-process-single mb-30 pos-rel" style={{border:"1px solid #eb6502"}}>
                  <div className="step"></div>
                  <div className="work-process-content">
                    <div className="process-icon mb-40">
                      <img
                        src="assets/img/globl/flexible_investment_packages.png"
                        alt="Flexible Investment Packages"
                      />
                    </div>
                    <h4 className="process-title">
                      <a>Flexible Investment Packages</a>
                    </h4>
                    <p className>
                      With investment options starting from just $50, Global
                      Grow offers flexibility for all types of investors.
                      Whether you want to test the waters or invest big, you can
                      choose the package that fits your financial goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="work-process-single mb-30 pos-rel">
                  <div className="step"></div>
                  <div className="work-process-content">
                    <div className="process-icon mb-40">
                      <img
                        src="assets/img/globl/user_friendly_platform.png"
                        alt="User-Friendly Platform"
                      />
                    </div>
                    <h4 className="process-title">
                      <a>User-Friendly Platform</a>
                    </h4>
                    <p className>
                      Global Grow’s platform is designed with simplicity and
                      ease of use in mind. From wallet connection to accessing
                      trading bots, the process is straightforward, making it
                      accessible even for newcomers to crypto trading.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="work-process-single mb-4 pos-rel" style={{border:"1px solid #eb6502"}}>
                  <div className="step"></div>
                  <div className="work-process-content mb-4">
                    <div className="process-icon mb-40 ">
                      <img
                        src="assets/img/globl/referralrewards_program.png"
                        alt="Referral Rewards Program"
                      />
                    </div>
                    <h4 className="process-title">
                      <a>Referral Rewards Program</a>
                    </h4>
                    <p className="pb-2">
                      Boost your earnings by participating in our referral
                      program. Invite others to join Global Grow and unlock
                      additional rewards, creating a passive income stream
                      alongside your trading profits.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="work-process-single mb-30 pos-rel">
                  <div className="step"></div>
                  <div className="work-process-content">
                    <div className="process-icon mb-40">
                      <img
                        src="assets/img/globl/real_time_performance_monitoring.png"
                        alt="Real-Time Performance Monitoring"
                      />
                    </div>
                    <h4 className="process-title">
                      <a>Real-Time Performance Monitoring</a>
                    </h4>
                    <p className>
                      Stay in control of your investments with real-time data
                      and analytics. Monitor the performance of your trading
                      bots and track your earnings with our comprehensive
                      dashboard, ensuring full transparency at all times.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="work-process-single mb-30 pos-rel" style={{border:"1px solid #eb6502"}}>
                  <div className="step"></div>
                  <div className="work-process-content">
                    <div className="process-icon mb-40">
                      <img
                        src="assets/img/globl/secure_reliable.png"
                        alt="Secure & Reliable"
                      />
                    </div>
                    <h4 className="process-title">
                      <a>Secure & Reliable</a>
                    </h4>
                    <p className>
                      Security is at the forefront of Global Grow’s platform. We
                      ensure the safety of your funds and personal information
                      with advanced encryption protocols, so you can trade with
                      peace of mind knowing your assets are well-protected.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
