import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './Componant/Home/Index';
import Dashboard from './Componant/Dashboard/Dashboard';
import Staking from './Componant/Dashboard/Staking';
import Withdrawal from './Componant/Dashboard/Withdrawal/Withdrawal';
import Team from './Componant/Dashboard/Team';
import Dividend from './Componant/Dashboard/Dividend';
import DirectReward from './Componant/Dashboard/DirectReward';
import LevelReward from './Componant/Dashboard/LevelReward';
import MatchingReward from './Componant/Dashboard/MatchingReward';
import RoyalRank from './Componant/Dashboard/RoyalRank';
import Stake from './Componant/Dashboard/Stake';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Index />}/>

      <Route path="/dashboard" element={<Dashboard />}/>
      <Route path="/staking" element={<Staking />}/>
      <Route path="/team" element={<Team />}/>
      <Route path="/dividend" element={<Dividend />}/>
      <Route path="/direct-reward" element={<DirectReward />}/>
      <Route path="/level-reward" element={<LevelReward />}/>
      <Route path="/matching-reward" element={<MatchingReward />}/>
      <Route path="/royal-rank" element={<RoyalRank />}/>
      <Route path="/stake" element={<Stake />}/>
      <Route path="/withdrawal" element={< Withdrawal />}>
        
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
