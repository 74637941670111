import React from "react";

export default function Network() {
  return (
    <div>
      <div className="banner-area banner-area1 pos-rel fix ">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="single-banner single-banner-1 d-flex align-items-center pos-rel pb-110">
                <div className="container pos-rel">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <div className="banner-content banner-content1 banner-content1-1 pt-0">
                        <h2
                          data-animation="fadeInUp"
                          data-delay=".3s"
                          className="mb-25 font-prata"
                        >
                          Join the Global Grow
                          <span> Network </span>
                        </h2>
                        <p
                          data-animation="fadeInUp"
                          data-delay=".5s"
                        >
                          Global Grow is more than just a platform – it’s a
                          community of forward-thinking investors, traders and
                          crypto enthusiasts. Connect with other like-minded
                          people, share ideas, and stay informed with the latest
                          updates in the crypto trading space.
                        </p>
                        <p
                          data-animation="fadeInUp"
                          data-delay=".5s"
                          className="mb-40"
                        >
                          Be part of the future with Global Grow!
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 col-md-12 px-2 px-md-0 d-flex justify-content-center">
                      <div>
                        <div className="oc-banner-img-3 stuff3">
                          <img
                            src="assets/img/globl/join_the_global_grow_network_image.jpg"
                            style={{ borderRadius: "25px" }}
                            className="img-fluid"
                            alt="   Join the Global Grow Network"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* If we need navigation buttons */}
          <div className="slider-nav d-none">
            <div className="slider-button-prev">
              <i className="fal fa-long-arrow-left" />
            </div>
            <div className="slider-button-next">
              <i className="fal fa-long-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
