import React from 'react'
import Header from '../Coman/Header'
import Hero from './Hero'
import Main from './Main'
import Category from './Category'
import Create from './Create'
import Faq from './Faq'
import Footer from './Footer'
import Network from './Network'
import Accelerate from './Accelerate'

export default function Index() {
  return (
    <div>
        <Header/>
        <Hero/>
        <Main/>
        <Category/>
        <Create/>
        <Network/>
        <Accelerate/>
        <Faq/>
        <Footer/>
    </div>
  )
}
