import React, { useState } from "react";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  // Toggle function to open/close menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div>
      <div>
        <header className="header1 oc-transparent-header">
          <div id="header-sticky" className="header-main header-main1">
            <div className="container header-container">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2 col-md-4 col-4">
                  <div className="header-main-left">
                    <div className="header-logo header1-logo">
                      <a href="/" className="logo-bb">
                        <img
                          src="assets/img/globl/logo.png"
                          alt="logo-img"
                          width={200}
                        />
                      </a>
                      <a href="/" className="logo-bw">
                        <img
                          src="assets/img/globl/logo.png"
                          alt="logo-img"
                          width={200}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-10 col-lg-10 col-md-8 col-8">
                  <div className="header-main-right">
                    <div className="main-menu main-menu1 d-none d-lg-block">
                      <nav id="mobile-menu">
                        <ul>
                          <li className="menu-item-h">
                            <a href="#">Features</a>
                          </li>
                          <li>
                            <a href="#">Trading Bots</a>
                          </li>
                          <li>
                            <a href="#">Copy-Trading</a>
                          </li>
                          <li className="menu-item-ha">
                            <a href="#">Pricing
                            </a>
                          </li>
                          <li>
                            <a href="#">Blog</a>
                          </li>
                          {/* <li>
                            <a href="contact.html">Contact</a>
                          </li> */}
                        </ul>
                      </nav>
                    </div>

                    <div className="header-btn ml-20 d-none d-lg-block">
                      <a href="/dashboard" className="fill-btn">
                        Connect Wallet
                      </a>
                    </div>
                    <div className="profile-item profile-item-header ml-20 d-none d-md-inline-block pos-rel"></div>
                    <div className="menu-bar d-block d-lg-none ml-20">
                      <a className="side-toggle" href="javascript:void(0)">
                        <div className="bar-icon" onClick={toggleMenu}>
                          <span />
                          <span />
                          <span />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* header area end */}
        {/* side toggle start */}
        <div className="fix">
          <div
            className={isMenuOpen ? "side-info  d-none" : "side-info info-open"}
          >
            <div className="side-info-content">
              <div className="offset-widget offset-logo mb-40">
                <div className="row align-items-center">
                  <div className="col-9">
                    <a href="/">
                      <img
                        src="assets/img/globl/logo.png"
                        width={200}
                        alt="Logo"
                      />
                    </a>
                  </div>
                  <div className="col-3 text-end">
                    <button className="side-info-close" onClick={toggleMenu}>
                      <i className="fal fa-times" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mobile-menu d-lg-none fix" />
              <div className="offset-profile-action">
                <div className="offset-widget mb-40">
                  <div className="profile-item profile-item-header into-sidebar">
                    <div className="profile-img ">
                      <div className="main-menu main-menu1 ">
                        <nav>
                          <ul style={{ zIndex: "999", position: "relative" }}>
                            <li className="menu-item-ha text-white">
                              <a href="#" className="py-1">
                              Features
                              </a>
                            </li>
                            <li className="text-white">
                              <a href="explore-arts.html" className="py-1">
                              Trading Bots
                              </a>
                            </li>
                            <li className="text-white">
                              <a href="creators.html" className="py-1">
                              Copy-Trading
                              </a>
                            </li>
                            <li className="menu-item-has-children text-white">
                              <a href="#" className="py-1">
                              Pricing
                              </a>
                            </li>
                            <li className="text-white">
                              <a href="creators.html" className="py-1">
                              Blog
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="offset-widget offset_searchbar mb-30"></div>
              <div className="offset-widget mb-40">
                <a href="/dashboard" className="fill-btn">
                Connect Wallet
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-overlay " />
        <div className="offcanvas-overlay-white" />
      </div>
    </div>
  );
}
