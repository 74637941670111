import React from 'react'
import Header2 from '../../Coman/Header2'
import Sidebar from '../../Coman/Sidebar'
import WithdrawalBox from './WithdrawalBox'

export default function Withdrawal() {
  return (
    <div>
          <Header2 />
          <Sidebar />
          <WithdrawalBox/>
          {/* <div style={{ backgroundColor: "#eff1f5" }}>
        <div className="banner-area banner-area3 pos-rel pt-130">
          <div className="container c-container-1">
<h2>Staking</h2>
          <div className="rank-list-container wow fadeInUp" style={{visibility: 'visible', animationName: 'fadeInUp'}}>
  <div className="rank-list-wrapper mb-30">
    <div className="rank-list-row-heading">
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl">Sr</div>
        <div className="rank-list-cell rank-list-cell-market">Reward Eligibility	</div>
        <div className="rank-list-cell rank-list-cell-volume">Stake($)	</div>
        <div className="rank-list-cell rank-list-cell-hours">Rate</div>
        <div className="rank-list-cell rank-list-cell-days">Datetime</div>
        <div className="rank-list-cell rank-list-cell-bids">Status</div>
        <div className="rank-list-cell rank-list-cell-price">Grand Price</div>
        <div className="rank-list-cell rank-list-cell-owner">Owner</div>
        <div className="rank-list-cell rank-list-cell-assets">Assets</div>
      </div>
    </div>
    <div className="rank-list-items">
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
     
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
        <div className="rank-list-cell rank-list-cell-price">2.43 ETH</div>
        <div className="rank-list-cell rank-list-cell-owner">Stive Machman</div>
        <div className="rank-list-cell rank-list-cell-assets">35.5k</div>
      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>

        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
        <div className="rank-list-cell rank-list-cell-price">2.43 ETH</div>
        <div className="rank-list-cell rank-list-cell-owner">Jobanico Mina</div>
        <div className="rank-list-cell rank-list-cell-assets">35.5k</div>
      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
  
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
        <div className="rank-list-cell rank-list-cell-price">2.43 ETH</div>
        <div className="rank-list-cell rank-list-cell-owner">Kallaban Joy</div>
        <div className="rank-list-cell rank-list-cell-assets">35.5k</div>
      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
  
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
        <div className="rank-list-cell rank-list-cell-price">2.43 ETH</div>
        <div className="rank-list-cell rank-list-cell-owner">John Schreffler</div>
        <div className="rank-list-cell rank-list-cell-assets">35.5k</div>
      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
 
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
        <div className="rank-list-cell rank-list-cell-price">2.43 ETH</div>
        <div className="rank-list-cell rank-list-cell-owner">Kallaban Joy</div>
        <div className="rank-list-cell rank-list-cell-assets">35.5k</div>
      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
    
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
        <div className="rank-list-cell rank-list-cell-price">2.43 ETH</div>
        <div className="rank-list-cell rank-list-cell-owner">Kenny Chess</div>
        <div className="rank-list-cell rank-list-cell-assets">35.5k</div>
      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
      
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
        <div className="rank-list-cell rank-list-cell-price">2.43 ETH</div>
        <div className="rank-list-cell rank-list-cell-owner">Margaret Krom</div>
        <div className="rank-list-cell rank-list-cell-assets">35.5k</div>
      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
  
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
        <div className="rank-list-cell rank-list-cell-price">2.43 ETH</div>
        <div className="rank-list-cell rank-list-cell-owner">Murray Johnson</div>
        <div className="rank-list-cell rank-list-cell-assets">35.5k</div>
      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
  
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
        <div className="rank-list-cell rank-list-cell-price">2.43 ETH</div>
        <div className="rank-list-cell rank-list-cell-owner">Carol G. Johnson</div>
        <div className="rank-list-cell rank-list-cell-assets">35.5k</div>
      </div>
      <div className="rank-list-row">
        <div className="rank-list-cell rank-list-cell-sl"><span /></div>
      
        <div className="rank-list-cell rank-list-cell-market">Local</div>
        <div className="rank-list-cell rank-list-cell-volume">10,4954</div>
        <div className="rank-list-cell rank-list-cell-hours">+100%</div>
        <div className="rank-list-cell rank-list-cell-days"> -98.45%</div>
        <div className="rank-list-cell rank-list-cell-bids">98</div>
        <div className="rank-list-cell rank-list-cell-price">2.43 ETH</div>
        <div className="rank-list-cell rank-list-cell-owner">Wesley Sickler</div>
        <div className="rank-list-cell rank-list-cell-assets">35.5k</div>
      </div>
    </div>
  </div>
</div>

          </div>
        </div>
      </div> */}
    </div>
  )
}
