import React from "react";
import Sidebar from "../Coman/Sidebar";

export default function Stake() {
  return (
    <div>
        <Sidebar/>
      <div style={{ backgroundColor: "#eff1f5" }}>
        <div className="banner-area banner-area3 pos-rel pt-130">
          <div className="container c-container-1">
            {/* <h2>Stake</h2> */}
            <div className="row justify-content-center mt-4">
              <div className="col-xxl-6 col-xl-7 col-lg-8">
                <div
                  className="login-wrapper pos-rel mb-40 wow fadeInUp bg-white"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className=" login-inner">
                    <div className="login-content">
                      <h4>Stake</h4>
                      <form className="login-form" action="#">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="single-input-unit">
                              <label htmlFor="m-id">Referral Address</label>
                              <input
                                type="email"
                                name="m-id"
                                id="m-id"
                                placeholder="Enter Your Referral "
                                style={{ border: "1px solid black" }}
                              />
                            </div>
                            <h4 className="text-center mb-4">Min: $50</h4>
                            <div className="single-input-unit">
                              <label htmlFor="m-id">Referral Address</label>
                              <input
                                type="email"
                                name="m-id"
                                id="m-id"
                                placeholder="Enter Your Referral "
                                style={{ border: "1px solid black" }}
                              />
                            </div>
                            <div className="art-info-wrapper">
                              <ul>
                                <li>
                                  <span className="art-info-title">Size</span>
                                  600 x 1000px (37MB)
                                </li>
                                <li>
                                  <span className="art-info-title">
                                    Formats
                                  </span>
                                  JPEG / PNG / PDF
                                </li>
                                <li>
                                  <span className="art-info-title">
                                    Contract
                                  </span>
                                  OcXuy69.....hxoFkD
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="login-btn">
                          <button className="fill-btn">Stake</button>
                         
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
