import React from "react";
import { Link } from "react-router-dom";

export default function DashboardMain() {
  return (
    <div style={{ backgroundColor: "#eff1f5" }}>
      <div className="banner-area banner-area3 pos-rel pt-130">
        <div className="container c-container-1">
          <div className="row">
            <div className="col-xl-6">
              <div className="work-process-single mb-30 pos-rel text-start">
                <div className="work-process-content">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5 className=" text-start">
                        <a>Balance</a>
                      </h5>
                      <h1 className="text-start ">$0</h1>
                    </div>
                    <div>
                      <h5 className=" text-start">
                        <a>Rank</a>
                      </h5>
                      <h1 className="text-start ">NO</h1>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    <div>
                      <h4 className="process-title">$ 0</h4>
                      <h4>Required Business</h4>
                    </div>
                    <div>
                      <h4 className="process-title">$ 0</h4>
                      <h4>Required Business</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="work-process-single mb-30 pos-rel text-start">
                <div className="work-process-content">
                  <h5 className=" text-start">
                    <a>
                      Validator -<i class="fa fa-copy ms-3"></i>
                    </a>
                  </h5>
                  <h5 className=" text-start">
                    <a>
                      Deligator -<i class="fa fa-copy ms-3"></i>
                    </a>
                  </h5>
                  <div className="mt-4 d-flex justify-content-center">
                    <Link to="/stake" class="fill-btn me-3">
                      Stake Now
                    </Link>
                    <a href="#" class="fill-btn ms-3">
                      Withdrawal
                    </a>
                  </div>
                  <div className="mt-3 d-flex justify-content-center">
                    <a href="#" class="fill-btn me-3">
                      Sponsor Link
                    </a>
                    <a href="#" class="fill-btn ms-3">
                      Buy BBT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12">
            <div
              className="row wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="col-lg-8 col-md-8 col-sm-8">
                <div className="section-title1">
                  <h2 className="section-main-title1 ">Statistics </h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                {/* <div className="t-seller-filter d-flex justify-content-sm-end mb-40">
                  <form action="#">
                    <select
                      name="ts-select"
                      id="ts-select"
                      className="top-seller-select"
                      style={{ display: "none" }}
                    >
                      <option value={1}>1 Day</option>
                      <option value={2}>7 Day</option>
                      <option value={3}>1 Month</option>
                    </select>
                    <div className="nice-select top-seller-select" tabIndex={0}>
                      <span className="current">1 Day</span>
                      <ul className="list">
                        <li data-value={1} className="option selected">
                          1 Day
                        </li>
                        <li data-value={2} className="option">
                          7 Day
                        </li>
                        <li data-value={3} className="option">
                          1 Month
                        </li>
                      </ul>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
            <div className>
              <div
                className="row wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Stake</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Team</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">My Team</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Stake</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Dividend</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Direct Reward
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Level Reward
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Level Open</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Matching Reward
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Royal Rank</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Eligibility
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Current Rank
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Next Rank</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">Leg C</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">R Leg A</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">R Leg B</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">R Leg C</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Total Withdrawal
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-12">
                  <div className="creator-single creator-single-short creator-single-filled mb-30">
                    <div className="creator-wraper">
                      <div className="creator-inner">
                        <div className="creator-content pos-rel creator-short-content">
                          <div className="profile-img pos-rel">
                            <a>
                              <img
                                src="assets/img/profile/profile1.jpg"
                                alt="profile-img"
                              />
                            </a>
                          </div>
                          <div className="creator-info">
                            <div className>
                              <h4 className="artist-name pos-rel">
                                <a>$0</a>
                              </h4>

                              <div className="artist-meta-item">
                                <div className="artist-created">
                                  Pending Withdrawal
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
