import React from "react";

export default function Hero() {
  return (
    <div>
      <div className="banner-area banner-area1 pos-rel fix">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="single-banner single-banner-1 banner-900 d-flex align-items-center pos-rel mb-30">
                <img
                  src="assets/img/banner/b1-shape1.png"
                  alt=""
                  className="b1-shape1"
                />
                <div
                  className="banner-bg"
                  data-background="assets/img/banner/oc-banner-bg.png"
                />
                <div
                  className="banner-bg-light"
                  data-background="assets/img/banner/oc-banner-bg-light.png"
                />
                <div className="container pos-rel">
                  <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <div className="banner-content banner-content1 banner-content1-1 pt-0">
                        <h1
                          data-animation="fadeInUp"
                          data-delay=".3s"
                          className="mb-25 font-prata"
                        >
                          Optimize Your Trades with BBT
                          <span> Crypto and Forex Bots </span>
                        </h1>
                        <p
                          data-animation="fadeInUp"
                          data-delay=".5s"
                          className="mb-40"
                        >
                          Maximize your trading potential with BBT Crypto and
                          Forex Bots. Automate strategies, optimize trades, and
                          stay ahead of market trends with precision and speed.
                        </p>
                        <div
                          className="banner-btn mb-4"
                          data-animation="fadeInUp"
                          data-delay=".7s"
                        >
                          <a href="#" className="fill-btn">
                            Join Now
                          </a>
                          <a href="#" className="fill-btn">
                          presentation
                          </a>
                          {/* <div className="oc-banner-video">
                            <a
                            href="https://www.youtube.com/watch?v=XHOmBV4js_E"
                            className="popup-video"
                            >
                              <i className="fas fa-play" />
                            </a>
                            <span>Watch Video</span>
                          </div> */}
                        </div>
                        <p>Start trading smarter today!</p>
                        {/* <div className="oc-banner-counter">
                          <div className="art-meta-item">
                            <div className="art-meta-notice">
                              <span className="counter">$50k+ </span>
                            </div>
                            <div className="art-meta-type">TVL</div>
                          </div>
                          <div className="art-meta-item">
                            <div className="art-meta-notice">
                              <span className="counter">1k+</span>
                            </div>
                            <div className="art-meta-type">Members</div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 col-md-12">
                      <div className="oc-banner-img pos-rel">
                        {/* <div className="oc-banner-img-1 stuff">
                          <img
                            className
                            data-depth=".5"
                            src="assets/img/banner/oc-banner-1.jpg"
                            alt="img not found"
                          />
                        </div>
                        <div className="oc-banner-img-2 stuff2">
                          <img
                            className
                            data-depth=".6"
                            src="assets/img/banner/oc-banner-2.jpg"
                            alt="img not found"
                          />
                        </div> */}
                        <div className="oc-banner-img-3 stuff3">
                          <img
                            src="assets/img/globl/hero_section_image.png"
                            className="img-fluid"
                            alt="img not found"
                          />
                        </div>
                        {/* <div className="oc-banner-img-4 stuff4">
                          <img
                            className
                            data-depth=".5"
                            src="assets/img/banner/oc-banner-4.jpg"
                            alt="img not found"
                          />
                        </div>
                   */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* If we need navigation buttons */}
          <div className="slider-nav d-none">
            <div className="slider-button-prev">
              <i className="fal fa-long-arrow-left" />
            </div>
            <div className="slider-button-next">
              <i className="fal fa-long-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
