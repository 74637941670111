import React from "react";

export default function Accelerate() {
  return (
    <div>
      <div className="  d-flex align-items-center pt-0 ">
        <div className="container mt-5  ">
          <div className="row justify-content-center">
            <div className="col-xl-10 ">
              <div
                className="personal-info-text d-flex align-items-center bg-ready py-4"
                // style={{ background: "blue" }}
              >
                <div className="ms-md-4 my-3">
                  <h3 className="text-white">Ready to Accelerate?</h3>
                  <p className="text-white mt-3">
                    Step into the future of automated trading with Global Grow.
                    Sign up today and transform your trading strategies from
                    scratch with achieving higher profitability. Embrace the
                    power of crypto and forex trading to accelerate your growth
                    with Global Grow!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
