import React from "react";

export default function WithdrawalBox() {
  return (
    <div>
      <div style={{ backgroundColor: "#eff1f5" }}>
        <div className="banner-area banner-area3 pos-rel pt-130 vh-100">
          <div className="container c-container-1">
            <h2 className="text-center">Withdrawal</h2>
            <div className="row justify-content-center mt-4">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                <div
                  className="login-wrapper pos-rel mb-40 wow fadeInUp bg-white"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className=" login-inner">
                    <div className="login-content">
                      <h4>Sign in Account</h4>
                      <form className="login-form" action="#">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="single-input-unit">
                              <div className="d-flex justify-content-between">
                                <label htmlFor="m-id">Email</label>
                                <h4 className="m-0">$0</h4>
                              </div>
                              <input
                                type="email"
                                name="m-id"
                                id="m-id"
                                placeholder="Your email"
                                style={{ border: "1px solid black" }}
                              />
                            </div>
                          </div>
                          <div className="art-info-wrapper">
                            <ul>
                              <li>
                                <span className="art-info-title">Size</span>600
                                x 1000px (37MB)
                              </li>
                              <li>
                                <span className="art-info-title">Formats</span>
                                JPEG / PNG / PDF
                              </li>
                              <li>
                                <span className="art-info-title">Contract</span>
                                OcXuy69.....hxoFkD
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="login-btn">
                          <button className="fill-btn">Withdraw now</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
    <div className="login-wrapper pos-rel mb-40 wow fadeInUp bg-white" style={{visibility: 'visible', animationName: 'fadeInUp'}}>
      <div className=" login-inner">
        <div className="login-content">
          <h4>Sign in Account</h4>
          <form className="login-form" action="#">
            <div className="row">
              <div className="col-md-12">
                <div className="single-input-unit">
                  <label htmlFor="m-id">Email</label>
                  <input type="email" name="m-id" id="m-id" placeholder="Your email" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-input-unit">
                  <label htmlFor="u-name">Username</label>
                  <input type="text" name="u-name" id="u-name" placeholder="Username" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-input-unit">
                  <label htmlFor="password">Password</label>
                  <input type="password" name="password" id="password" placeholder="********" />
                </div>
              </div>
            </div>
            <div className="login-btn">
              <button className="fill-btn">Sign in Account</button>
              <div className="note">Not yet registered? <a href="register.html" className="text-btn">Sign
                  up</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
