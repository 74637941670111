import React, { useState } from "react";

export default function Sidebar() {
  const [isSideOpen, setIsSideOpen] = useState(true);
  const currentPath = window.location.pathname;


  // Toggle function to open/close menu
  const toggleSide = () => {
    setIsSideOpen(!isSideOpen);
  };
  return (
    <div>
      <header className="header2">
        <div className="header-main header-main2">
          <div style={{position:"relative",left:"0"}}>
            <div className="header-main2-content">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-5 col-md-4 col-1">
                  <div className="header-main-left">
                    <div className="menu-bar mr-20 d-xxl-none">
                      <a className="side-toggle" href="javascript:void(0)">
                        <div
                          className="bar-icon left-bar-icon"
                          onClick={toggleSide}
                        >
                          <span />
                          <span />
                          <span />
                        </div>
                      </a>
                    </div>
                    {/* <form
                      action="#"
                      className="filter-search-input header-search d-none d-md-inline-block"
                    >
                      <input type="text" placeholder="Search keyword" />
                      <button>
                        <i className="fal fa-search" />
                      </button>
                    </form> */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-8 col-11">
                  <div className="header-main-right">
                    {/* d-none d-xxl-inline-block */}
                    <div className="header-btn ml-20 ">
                      <a href="#" className="fill-btn mx-2 mt-2 mt-sm-0 px-2 px-md-3" style={{boxShadow:"1px 2px 10px 0px #000000"}}>
                      $
                      0.000013 OMP
                      </a>
                      <a href="#" className="fill-btn mx-2 mt-2 mt-sm-0 px-2 px-md-3" style={{boxShadow:"1px 2px 10px 0px #000000"}}>
                      <span><i class='fas fa-wallet '/></span> &nbsp;
                      $0
                      </a>
                      <a href="#" className="fill-btn mx-2 mt-2 mt-sm-0 px-2 px-md-3" style={{boxShadow:"1px 2px 10px 0px #000000"}}> 
                      Signout
                      </a>
                    </div>
                    {/* <div className="profile-item profile-item-header ml-20 d-none d-md-inline-block pos-rel">
                      <div className="profile-img pos-rel">
                        <div className="profile-action">
                          <ul>
                            <li>
                              <a href="creator-profile-info-personal.html">
                                <i className="fal fa-user" />
                                Profile
                              </a>
                            </li>
                            <li>
                              <a href="login.html">
                                <i className="fal fa-sign-out" />
                                Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                        <img
                          src="assets/img/profile/profile4.jpg"
                          alt="profile-img"
                        />
                        <div className="profile-verification verified">
                          <i className="fas fa-check" />
                        </div>
                      </div>
                    </div> */}
                    
                    {/* <div className="mode-switch-wrapper my_switcher setting-option home3-mode-switch ml-25">
                <input type="checkbox" className="checkbox" id="chk" />
                <label className="label" htmlFor="chk">
                  <i className="fas fa-moon setColor dark theme__switcher-btn" data-theme="dark" />
                  <i className="fas fa-sun setColor light theme__switcher-btn" data-theme="light" />
                  <span className="ball" />
                </label>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div>
        <div className="fix">
          <div
            className={
              isSideOpen
                ? "menu2-side-bar-wrapper "
                : "menu2-side-bar-wrapper open "
            }
          >
            <div className="menu2-side-bar vh-100">
              <div className="side-info-content">
                <div className="offset-widget offset-logo mb-25">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <div className="header-logo header1-logo d-flex justify-content-between">
                        <a href="#" className="logo-bb">
                          <img
                            src="assets/img/globl/logo.png"
                            width={180}
                            alt="logo-img"
                          />
                        </a>

                        <button
                          className="side-info-close d-none-side-icon"
                          onClick={toggleSide}
                        >
                          <i
                            className="fal fa-times"
                            style={{ color: "black" }}
                          />
                        </button>
                        {/* <a href="#" className="logo-bw">
                          <img
                            src="assets/img/globl/logo.png"
                            width={200}
                            alt="logo-img"
                          />
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-menu main-menu1 ">
                  <nav id="menu2-mobile-menu">
                    <ul>
                      <li className=" side-w m-0">
                        <a href="/dashboard"   className={`${
                    currentPath === "/dashboard" 
                      ? " p-0 side-active"
                      : "p-0  "
                  }`}>
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Dashboard
                        </a>
                        {/* <ul className="sub-menu">
                    <li><a href="index.html">Home Style 01</a></li>
                    <li><a href="index-2.html">Home Style 02</a></li>
                    <li><a href="index-3.html">Home Style 03</a></li>
                  </ul> */}
                      </li>
                      <li className="side-w m-0">
                        <a href="/staking" className={`${
                    currentPath === "/staking" 
                      ? " p-0 side-active"
                      : " p-0 "
                  }`} >
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Staking
                        </a>
                      </li>
                      <li className="side-w m-0">
                        <a href="/team" className={`${
                    currentPath === "/team" 
                      ? " p-0 side-active"
                      : " p-0 "
                  }`}>
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Team
                        </a>
                      </li>
                      <li className="side-w m-0">
                        <a href="/dividend" className={`${
                    currentPath === "/dividend" 
                      ? " p-0 side-active"
                      : " p-0 "
                  }`}>
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Dividend
                        </a>
                      </li>
                      <li className="side-w m-0">
                        <a href="/direct-reward"  className={`${
                    currentPath === "/direct-reward" 
                      ? " p-0 side-active"
                      : " p-0 "
                  }`}>
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Direct Reward
                        </a>
                      </li>
                      {/* <li className="side-w m-0">
                        <a href="#" className="p-0">
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp; Super Reward
                        </a>
                      </li> */}
                      <li className="side-w m-0">
                        <a href="/level-reward"  className={`${
                    currentPath === "/level-reward" 
                      ? " p-0 side-active"
                      : " p-0 "
                  }`}>
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;  Level Reward
                        </a>
                      </li>
                      <li className="side-w m-0">
                        <a href="/matching-reward"  className={`${
                    currentPath === "/matching-reward" 
                      ? " p-0 side-active"
                      : " p-0 "
                  }`}>
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Matching Reward
                        </a>
                      </li>
                      <li className="side-w m-0" >
                        <a href="/royal-rank"  className={`${
                    currentPath === "/royal-rank" 
                      ? " p-0 side-active"
                      : " p-0 "
                  }`}>
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Royal Rank
                        </a>
                      </li>
                      {/* <li className="side-w m-0">
                        <a href="#" className="p-0">
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Rank Reward
                        </a>
                      </li> */}
                      <li className="side-w m-0">
                        <a href="/withdrawal"  className={`${
                    currentPath === "/withdrawal" 
                      ? " p-0 side-active"
                      : " p-0 "
                  }`}>
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Withdrawal
                        </a>
                      </li>
                      {/* <li className="side-w m-0">
                        <a href="#" className="p-0">
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Ledger
                        </a>
                      </li> */}
                    
                      <li className="side-w m-0">
                        <a href="#"  className={`${
                    currentPath === "#" 
                      ? " p-0 side-active"
                      : " p-0 "
                  }`}>
                          {" "}
                          <img
                            src="assets/img/profile/profile4.jpg"
                            width={30}
                            alt=""
                          />{" "}
                          &nbsp;Presentation
                        </a>
                      </li>
     

                      {/* <li className="menu-item-has-children side-w"><a href="#"> <img src="assets/img/profile/profile4.jpg" width={30} alt="" />  Staking Reward</a>
                  <ul className="sub-menu ">
                    <li className="menu-item-has-children"><a href="#">  <img src="assets/img/profile/profile4.jpg" width={30} alt="" />  Aggregator Ranks</a>
                      <ul className="sub-menu">
                        <li><a href="creator-profile.html">Creator Profile</a></li>
                        <li><a href="creator-profile-info-personal.html">Creator Personal Info</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children "><a href="#">  <img src="assets/img/profile/profile4.jpg" width={30} alt="" /> Super Reward</a>
                      <ul className="sub-menu">
                        <li><a href="art-details.html">Art Details</a></li>
                        <li><a href="upload-category.html">Upload Category</a></li>
                        <li><a href="upload.html">Upload</a></li>
                      </ul>
                    </li>
                    <li><a href="faq.html">FAQ</a></li>
                    <li><a href="register.html">Register</a></li>
                    <li><a href="login.html">Login</a></li>
                    <li><a href="terms.html">Terms</a></li>
                    <li><a href="wallet-connect.html">Wallet Connect</a></li>
                    <li><a href="activity.html">Activity</a></li>
                    <li><a href="art-ranking.html">Ranking</a></li>
                    <li><a href="error-404.html">404 page</a></li>
                  </ul>
                </li> */}
                    
                    </ul>
                    
                  </nav>
                  
                </div>
                <div className="menu2-mobile-menu fix"></div>
                <a href="#" class="fill-btn">
                Signout</a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isSideOpen ? "offcanvas-overlay" : "offcanvas-overlay overlay-open"
          }
        />

        <div className="offcanvas-overlay-white" />
      </div>
    </div>
  );
}
