import React from "react";

export default function Footer() {
  return (
    <div>
      <footer data-background className="footer1-bg">
        <section className="footer-area footer-area1 footer-area1-bg pt-100 pb-50">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="footer-widget mb-40">
                  <div className="footer-logo mb-30 d-flex justify-content-center">
                    <a href="#" className="logo-bb">
                      <img
                         src="assets/img/globl/logo.png"
                         width={200}
                        alt="logo-img"
                      />
                    </a>
                    <a href="#" className="logo-bw">
                      <img
                        src="assets/img/globl/logo.png"
                        width={200}
                        alt="logo-img"
                      />
                    </a>
                  </div>
                  {/* <p className="mb-35">
                    We provide one-stop solutions for all IT items; your bliss
                    is just a click away. Star Tech trusts in quality client
                  </p> */}
                  <div className="social__links footer__social d-flex  justify-content-center">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6">
                <div className="footer-widget footer1-widget footer1-widget2 mb-40">
                  <div className="footer-widget-title">
                    <h4>Marketplace</h4>
                  </div>
                  <ul>
                    <li>
                      <a href="#">Proper Guidelines</a>
                    </li>
                    <li>
                      <a href="#">Buy Products</a>
                    </li>
                    <li>
                      <a href="#">Compare Us</a>
                    </li>
                    <li>
                      <a href="#">Career</a>
                    </li>
                    <li>
                      <a href="#">Build Ecommerce</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget footer1-widget footer1-widget3 mb-40 ">
                  <div className="footer-widget-title">
                    <h4>Explore Artworks</h4>
                  </div>
                  <ul>
                    <li>
                      <a href="#">3D Artworks</a>
                    </li>
                    <li>
                      <a href="#">Photography</a>
                    </li>
                    <li>
                      <a href="#">Flat Illustration</a>
                    </li>
                    <li>
                      <a href="#">Painting</a>
                    </li>
                    <li>
                      <a href="#">Intro Videos</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget footer1-widget footer1-widget4 mb-40 ">
                  <div className="footer-widget-title">
                    <h4>Insight Community</h4>
                  </div>
                  <ul>
                    <li>
                      <a href="#">Global Partners</a>
                    </li>
                    <li>
                      <a href="#">Forum</a>
                    </li>
                    <li>
                      <a href="#">Virtual World</a>
                    </li>
                    <li>
                      <a href="#">Community</a>
                    </li>
                    <li>
                      <a href="#">Brand Assets</a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        {/* <div className="copyright-area copyright1-area">
          <div className="container">
            <div className="copyright1-inner">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-6">
                  <div className="copyright-text copyright1-text">
                    © Copyrighted &amp; Designed by{" "}
                    <a href="https://themeforest.net/user/bdevs">BDevs</a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="irc-item copyright-support copyright1-support copyright-support-lines">
                    <div className="irc-item-icon">
                      <i className="flaticon-support" />
                    </div>
                    <div className="irc-item-content">
                      <p>Have a question? Call us 24/7</p>
                      <a href="tel:(987)547587587">(987) 547587587</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12">
                  <form className="subscribe-form subscribe-form-copyright1">
                    <input type="text" placeholder="Enter email" />
                    <button type="submit">
                      Subscribe
                      <i className="fas fa-paper-plane" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </footer>
    </div>
  );
}
