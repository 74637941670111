import React, { useState } from "react";

export default function Faq() {
  const [isToggled, setIsToggled] = useState(true);
  const [isToggled2, setIsToggl] = useState(0);
  const [isToggled3, setIsTogglf] = useState(0);
  const [isToggled4, setIsTogglfa] = useState(0);
  const [isToggled5, setIsTogglfaq] = useState(0);
  const [isToggled6, setIsTogglfaqa] = useState(0);
  const [isToggled7, setIsTogglfaqaa] = useState(0);

  // Step 2: Function to toggle the state
  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };
  const handleToggl2 = () => {
    setIsToggl((prevState) => !prevState);
  };
  const handleToggl3 = () => {
    setIsTogglf((prevState) => !prevState);
  };
  const handleToggl4 = () => {
    setIsTogglfa((prevState) => !prevState);
  };
  const handleToggl5 = () => {
    setIsTogglfaq((prevState) => !prevState);
  };
  const handleToggl6 = () => {
    setIsTogglfaqa((prevState) => !prevState);
  };
  const handleToggl7 = () => {
    setIsTogglfaqaa((prevState) => !prevState);
  };
  return (
    <div>
      <section className="about-info-area pt-130 pb-90" data-background>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h3 className="text-center mb-4">
                Frequently Asked Questions (FAQs)
              </h3>

              <div className="faq-tab-contents mb-0 wow fadeInUp">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="tab-nav1"
                    role="tabpanel"
                    aria-labelledby="nav-tab1"
                  >
                    <div className="about-info-wrapper mb-30">
                      <div className="accordion accordion-general">
                        <div
                          className="accordion-item"
                          style={{ border: "1px solid black" }}
                        >
                          <h2 className="accordion-header" id="headingOneA">
                            <button
                              className={
                                isToggled
                                  ? "accordion-button "
                                  : "accordion-button collapsed"
                              }
                              type="button"
                              onClick={handleToggle}
                            >
                              What is Global Grow?
                            </button>
                          </h2>
                          <div
                            id="collapseOneA"
                            className={
                              isToggled
                                ? '"accordion-collapse collapse show "    '
                                : "accordion-collapse collapse "
                            }
                          >
                            <div className="accordion-body">
                              Global Grow is a crypto trading platform that
                              provides access to automated trading bots,
                              allowing investors and traders to trade any amount
                              they wish. It also offers a referral program to
                              maximize earnings. The platform is designed for
                              both beginners and experienced traders looking to
                              simplify and automate their trading process.
                            </div>
                          </div>
                        </div>

                        <div
                          className="accordion-item"
                          style={{ border: "1px solid black" }}
                        >
                          <h2 className="accordion-header" id="headingtoo">
                            <button
                              className={
                                isToggled2
                                  ? "accordion-button "
                                  : "accordion-button collapsed"
                              }
                              type="button"
                              onClick={handleToggl2}
                            >
                              How do automated trading bots work?
                            </button>
                          </h2>
                          <div
                            className={
                              isToggled2
                                ? '"accordion-collapse collapse show "    '
                                : "accordion-collapse collapse "
                            }
                          >
                            <div className="accordion-body">
                              Global Grow’s trading bots use sophisticated
                              algorithms to analyze the market and execute
                              trades on your behalf. They operate 24/7, ensuring
                              that you never miss out on potential trading
                              opportunities, even when you're away from the
                              platform.
                            </div>
                          </div>
                        </div>
                        <div
                          className="accordion-item"
                          style={{ border: "1px solid black" }}
                        >
                          <h2 className="accordion-header" id="headingthree">
                            <button
                              className={
                                isToggled3
                                  ? "accordion-button "
                                  : "accordion-button collapsed"
                              }
                              type="button"
                              onClick={handleToggl3}
                            >
                              What is the minimum investment amount?
                            </button>
                          </h2>
                          <div
                            id="collapsethree"
                            className={
                              isToggled3
                                ? '"accordion-collapse collapse show "    '
                                : "accordion-collapse collapse "
                            }
                          >
                            <div className="accordion-body">
                              The minimum investment on Global Grow starts at
                              just $50, allowing users to begin trading with a
                              low barrier to entry. You can also invest higher
                              amounts depending on your trading goals and
                              preferences.
                            </div>
                          </div>
                        </div>
                        <div
                          className="accordion-item"
                          style={{ border: "1px solid black" }}
                        >
                          <h2 className="accordion-header" id="headingfor">
                            <button
                              className={
                                isToggled4
                                  ? "accordion-button "
                                  : "accordion-button collapsed"
                              }
                              type="button"
                              onClick={handleToggl4}
                            >
                              How do I refer people to Global Grow?
                            </button>
                          </h2>
                          <div
                            id="collapsefor"
                            className={
                              isToggled4
                                ? '"accordion-collapse collapse show "    '
                                : "accordion-collapse collapse "
                            }
                          >
                            <div className="accordion-body">
                              After creating an account, you will be given a
                              unique referral link that you can share with
                              friends, family, or other traders. When they sign
                              up and start trading through your link, you will
                              earn referral rewards, unlocking additional
                              incentives.
                            </div>
                          </div>
                        </div>

                        <div
                          className="accordion-item"
                          style={{ border: "1px solid black" }}
                        >
                          <h2 className="accordion-header" id="headingFive">
                            <button
                              className={
                                isToggled5
                                  ? "accordion-button "
                                  : "accordion-button collapsed"
                              }
                              type="button"
                              onClick={handleToggl5}
                            >
                              Is my money safe on Global Grow?
                            </button>
                          </h2>
                          <div
                            id="collapseFive"
                            className={
                              isToggled5
                                ? '"accordion-collapse collapse show "    '
                                : "accordion-collapse collapse "
                            }
                          >
                            <div className="accordion-body">
                              Yes, Global Grow prioritizes security and uses
                              advanced encryption protocols to protect your
                              funds and personal information. We follow
                              industry-standard security measures to ensure a
                              safe trading environment for all users.
                            </div>
                          </div>
                        </div>
                        <div
                          className="accordion-item"
                          style={{ border: "1px solid black" }}
                        >
                          <h2 className="accordion-header" id="headingFive">
                            <button
                              className={
                                isToggled6
                                  ? "accordion-button "
                                  : "accordion-button collapsed"
                              }
                              type="button"
                              onClick={handleToggl6}
                            >
                              Can I monitor my trades and earnings in real-time?
                            </button>
                          </h2>
                          <div
                            id="collapseFive"
                            className={
                              isToggled6
                                ? '"accordion-collapse collapse show "    '
                                : "accordion-collapse collapse "
                            }
                          >
                            <div className="accordion-body">
                              Absolutely! Global Grow provides a comprehensive
                              dashboard where you can track the performance of
                              your trades and bots in real-time. This allows you
                              to stay updated on your investments and earnings
                              whenever you want.
                            </div>
                          </div>
                        </div>
                        <div
                          className="accordion-item"
                          style={{ border: "1px solid black" }}
                        >
                          <h2 className="accordion-header" id="headingFive">
                            <button
                              className={
                                isToggled7
                                  ? "accordion-button "
                                  : "accordion-button collapsed"
                              }
                              type="button"
                              onClick={handleToggl7}
                            >
                              How do I get started on Global Grow?
                            </button>
                          </h2>
                          <div
                            id="collapseFive"
                            className={
                              isToggled7
                                ? '"accordion-collapse collapse show "    '
                                : "accordion-collapse collapse "
                            }
                          >
                            <div className="accordion-body">
                              To get started, simply connect your cryptocurrency
                              wallet to the Global Grow platform, choose an
                              investment package, and activate your automated
                              trading bot. You can also refer people to earn
                              additional rewards as you continue trading.{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
