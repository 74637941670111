import React from 'react'
import Sidebar from '../Coman/Sidebar'

export default function RoyalRank() {
  return (
    <div>
    <Sidebar />
    <div style={{ backgroundColor: "#eff1f5" }}>
      <div className="banner-area banner-area3 pos-rel pt-130 vh-100">
        <div className="container c-container-1">
          <h2>Royal Rank</h2>
          <div
            className="rank-list-container wow fadeInUp mt-4"
            style={{ visibility: "visible", animationName: "fadeInUp" }}
          >
            <div className="rank-list-wrapper mb-30">
              <div className="rank-list-row-heading">
                <div className="rank-list-row">
                  <div className="rank-list-cell rank-list-cell-sl">Sr</div>
                  <div className="rank-list-cell rank-list-cell-market">
                    Address{" "}
                  </div>
                  <div className="rank-list-cell rank-list-cell-volume">
                    Validator{" "}
                  </div>
                  <div className="rank-list-cell rank-list-cell-hours">
                    Team Business{" "}
                  </div>
                  <div className="rank-list-cell rank-list-cell-days">
                    Staking
                  </div>
                  <div className="rank-list-cell rank-list-cell-bids">
                    Datetime
                  </div>

                  <div className="rank-list-cell rank-list-cell-assets">
                    Status
                  </div>
                </div>
              </div>
              <div className="rank-list-items">
                <div className="rank-list-row">
                  <div className="rank-list-cell rank-list-cell-sl">
                    <span />
                  </div>

                  <div className="rank-list-cell rank-list-cell-market">
                    Local
                  </div>
                  <div className="rank-list-cell rank-list-cell-volume">
                    10,4954
                  </div>
                  <div className="rank-list-cell rank-list-cell-hours">
                    +100%
                  </div>
                  <div className="rank-list-cell rank-list-cell-days">
                    {" "}
                    -98.45%
                  </div>
                  <div className="rank-list-cell rank-list-cell-bids">98</div>
                  <div className="rank-list-cell rank-list-cell-price">
                    2.43 ETH
                  </div>
                  <div className="rank-list-cell rank-list-cell-owner">
                    Stive Machman
                  </div>
                  <div className="rank-list-cell rank-list-cell-assets">
                    35.5k
                  </div>
                </div>
                <div className="rank-list-row">
                  <div className="rank-list-cell rank-list-cell-sl">
                    <span />
                  </div>

                  <div className="rank-list-cell rank-list-cell-market">
                    Local
                  </div>
                  <div className="rank-list-cell rank-list-cell-volume">
                    10,4954
                  </div>
                  <div className="rank-list-cell rank-list-cell-hours">
                    +100%
                  </div>
                  <div className="rank-list-cell rank-list-cell-days">
                    {" "}
                    -98.45%
                  </div>
                  <div className="rank-list-cell rank-list-cell-bids">98</div>
                  <div className="rank-list-cell rank-list-cell-price">
                    2.43 ETH
                  </div>
                  <div className="rank-list-cell rank-list-cell-owner">
                    Jobanico Mina
                  </div>
                  <div className="rank-list-cell rank-list-cell-assets">
                    35.5k
                  </div>
                </div>
                <div className="rank-list-row">
                  <div className="rank-list-cell rank-list-cell-sl">
                    <span />
                  </div>

                  <div className="rank-list-cell rank-list-cell-market">
                    Local
                  </div>
                  <div className="rank-list-cell rank-list-cell-volume">
                    10,4954
                  </div>
                  <div className="rank-list-cell rank-list-cell-hours">
                    +100%
                  </div>
                  <div className="rank-list-cell rank-list-cell-days">
                    {" "}
                    -98.45%
                  </div>
                  <div className="rank-list-cell rank-list-cell-bids">98</div>
                  <div className="rank-list-cell rank-list-cell-price">
                    2.43 ETH
                  </div>
                  <div className="rank-list-cell rank-list-cell-owner">
                    Kallaban Joy
                  </div>
                  <div className="rank-list-cell rank-list-cell-assets">
                    35.5k
                  </div>
                </div>
                <div className="rank-list-row">
                  <div className="rank-list-cell rank-list-cell-sl">
                    <span />
                  </div>

                  <div className="rank-list-cell rank-list-cell-market">
                    Local
                  </div>
                  <div className="rank-list-cell rank-list-cell-volume">
                    10,4954
                  </div>
                  <div className="rank-list-cell rank-list-cell-hours">
                    +100%
                  </div>
                  <div className="rank-list-cell rank-list-cell-days">
                    {" "}
                    -98.45%
                  </div>
                  <div className="rank-list-cell rank-list-cell-bids">98</div>
                  <div className="rank-list-cell rank-list-cell-price">
                    2.43 ETH
                  </div>
                  <div className="rank-list-cell rank-list-cell-owner">
                    Kallaban Joy
                  </div>
                  <div className="rank-list-cell rank-list-cell-assets">
                    35.5k
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
